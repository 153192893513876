import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes.js"

export const StyledBreadcrumb = styled.ol`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin-top: ${sizes.sizeL};
  margin-left: 0;
  padding: 20px 0;
  color: ${colors.colorTextPurple};

  li {
    padding-right: 0.5rem;

    &:first-of-type {
      &::after {
        content: " /";
      }
    }
  }

  a {
    text-decoration: none;
    color: #C6B1BD;

    &:hover {
      color: ${colors.colorContentDark};
    }

    &:active {
      color: ${colors.colorContentDark};
    }
  }
`
