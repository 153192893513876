import React from 'react';
import { Column100 } from "../column/Column"
import { ButtonContainerGumroad } from "../button/Button.js"
import { FormRow, StyledForm, StyledFormButton } from '../gumroadForm/style'
import { TextSmall } from '../text/Text.js'


const FormButton = ({ buttonText, buttonLink }) => (
  <StyledFormButton to={buttonLink} target="_blank">{buttonText}</StyledFormButton>
)
class GumroadForm extends React.Component { 

constructor(props) {
  super(props);
  this.state = {
    firstname: ' ', 
    lastname: ' ', 
    email: ' ', 
    agree: false, 
  };
  
  this.updateFirstName = this.updateFirstName.bind(this);
  this.updateLastName = this.updateLastName.bind(this);
  this.updateEmail = this.updateEmail.bind(this);
  this.updateAgreement = this.updateAgreement.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
}

updateFirstName(event) {
  console.log(this.state.firstname);
  this.setState({
    firstname: event.target.value
  })
} 

updateLastName(event) {
  console.log(this.state.lastname);
  this.setState({
    lastname: event.target.value
  })
} 

updateEmail(event) {
  console.log(this.state.email);
  this.setState({
    email: event.target.value
  })
} 

updateAgreement(event) {
  console.log('Checkbox clicked');
  this.setState({
    agree: event.target.checked
  })
}

handleSubmit(event) {
  event.preventDefault();

  let data = {
    "records": [
      {
        "fields": {
          "First name": this.state.firstname,
          "Last name": this.state.lastname,
          "Email": this.state.email,
          "Form name": this.props.formName,
          "Agree": this.state.agree       
        },
      },
    ],
    typecast: true 
  }

  let final_data = JSON.stringify(data);

  fetch(`https://api.airtable.com/v0/appWZOog67McA97vM/Main`, {
    method: "POST",
    body: final_data,
    headers: {
      "Authorization": `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
      "Content-Type": "application/json"
    },
  })
  .then( response => {
    console.log(response)
    window.location = `${this.props.buttonLink}` 
  })
  .catch( error => console.log(error) )
}

render() {

  return (
    <div >
      <StyledForm 
        onSubmit={this.handleSubmit} 
        method="POST" 
        name={this.props.formName}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        redirect={this.props.redirectPage} 
        > 
        <FormRow isCentered>
          <Column100 isCentered>
            <ButtonContainerGumroad isCentered>
              <FormButton type="submit" buttonText={this.props.buttonText} buttonLink={this.props.buttonLink} />
            </ButtonContainerGumroad>
            <TextSmall textSmall="*You will be redirected to Gumroad to buy this template. 
            You don't need an account there to pay for and access the document." />
          </Column100>
        </FormRow>
      </StyledForm>
    </div>
  );
}
}

export default GumroadForm;