import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  Title,
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/content-strategy-toolkit.jpg"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function ContentStrategyStarterKit({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Strategy Starter Kit - B2B SaaS" 
       description="This kit includes all the templates you need to get started with your B2B SaaS content strategy."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/content-strategy-starter-kit/">Content Strategy Starter Kit</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Content Strategy Starter Kit - B2B SaaS" />
            <SubHeading subheading="This kit includes all the templates you need to get started with your B2B SaaS content strategy." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/content-strategist-starting-kit" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Content Strategy Starter Kit - B2B SaaS" />
          </Column50>
        </Row>
      </AboveFold>


      <Section>
        <BlogRow isCentered>
          <TextMedium textMedium="This toolkit includes 7 templates and video guides, along with a website wireframe kit, to get you up and running in no time."/> 
            <TextMedium textMedium="Template and video guide for auditing your website content, Template and video guide for building your content strategy, 
            Content calendar template and video guide for creating your content calendar in Airtable, A list of 180 content ideas proven to work for B2B SaaS companies,
            Wireframe kit for building your website pages, ABM campaign template, to get you up and running with your first account-based campaign,
            Social media templates for promoting different types of content, Video guide for structuring your paid campaign." />
          <GumroadForm 
                formName="Content Strategy Starter Kit"
                redirectPage="https://socontent.gumroad.com/l/content-strategist-starting-kit"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/content-strategist-starting-kit"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentStrategyStarterKit